// import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router";
import DashboardOptions from "./Components/Dashboard/DashboardOptions";
// import LandingPage from "./Components/LandingPage";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomerOrder from "./SparepartsPages/CustomerOrder/CustomerOrder";
import Loginpage from "./SparepartsPages/LoginPages/Loginpage";
import LedgerMaster from "./SparepartsPages/LedgerMaster/LedgerMaster";
import Other from "./SparepartsPages/OtherMaster/Other";
import SpareLandingItemMaster from "./SparepartsPages/SparepartsLandingPage/SpareLandingItemMaster";
import MainLedger from "./SparepartsPages/MainLedger/MainLedger";
import Salesman from "./SparepartsPages/Salesman/Salesman";
import Transporter from "./SparepartsPages/Transporter/Transporter";
import MakerPicker from "./SparepartsPages/MakerPicker/MakersPickers";
import Subledger from "./SparepartsPages/Subledger/Subledger";
import WelcomePage from "./SparepartsPages/WelcomeSpareparts/WelcomePage";
import BranchSubledger from "./SparepartsPages/BranchSubledger/BranchSubledger";
import Transit from "./SparepartsPages/Transit/Transit";
import PurchaseOrder from "./SparepartsPages/PurchaseOrder/PurchaseOrder";
import AlternetItem from "./SparepartsPages/AlternetItem/AlternetItem";
import Model from "./SparepartsPages/Model/Model";
import Technicians from "./SparepartsPages/Technicians/Technicians";
import JobType from "./SparepartsPages/Jobtype/JobType";
import Jobcard from "./SparepartsPages/Jobcard/Jobcard";
import SaleBill from "./SparepartsPages/SaleBill/SaleBill";
import Banks from "./SparepartsPages/Banks/Banks";
import Narrations from "./SparepartsPages/Narrations/Narrations";
import MoneyReceipt from "./SparepartsPages/MoneyReceipt/MoneyReceipt";
import DirectSale from "./SparepartsPages/DirectSale/DirectSale";
// import TestReport from "./SparepartsPages/Reports/TestReport";
import TestReport2 from "./SparepartsPages/Reports/TestReport2";
import SaleReturn from "./SparepartsPages/SaleReturn/SaleReturn";
import CombinedSalebill from "./SparepartsPages/Reports/CombinedSalebill";
import SaleRegdSum from "./SparepartsPages/Reports/SaleRegdSum";
import SalesmanwiseBilldetail from "./SparepartsPages/Reports/SalesmanwiseBilldetail";
import SaleOrderStatus from "./SparepartsPages/SaleOrderStatus/SaleOrderStatus";
import BackOrder from "./SparepartsPages/BackOrder/BackOrder";
import TopSlow from "./SparepartsPages/Reports/TopSlow";
import StockAdjustRegister from "./SparepartsPages/Reports/StockAdjustRegister";
import StockSummary from "./SparepartsPages/Reports/StockSummary";
import StockLedgerBinCard from "./SparepartsPages/Reports/StockLedgerBinCard";
import NonMovingPartsList from "./SparepartsPages/Reports/NonMovingPartsList";
import BillOutstanding from "./SparepartsPages/Reports/BillOutstanding";
import RackwiseStockList from "./SparepartsPages/Reports/RackwiseStockList";
import PickerPerformance from "./SparepartsPages/Reports/PickerPerformance";
import PurchaseRegister from "./SparepartsPages/Reports/PurchaseRegister";
import CustomerOrderReport from "./SparepartsPages/Reports/CustomerOrderReport";
import SaleReturnRegister from "./SparepartsPages/Reports/SaleReturnRegister";
import WarrantyRegister from "./SparepartsPages/Reports/WarrantyRegister";
import OilConsumption from "./SparepartsPages/Reports/OilConsumption";
import SalesmanPartySummary from "./SparepartsPages/Reports/SalesmanPartySummary";
import GovtGst from "./SparepartsPages/Reports/GovtGst";
import HsnReport from "./SparepartsPages/Reports/HsnReport";
import BajajMIS from "./SparepartsPages/Reports/BajajMIS";
import PartywiseOrderBill from "./SparepartsPages/Reports/PartywiseOrderBill";
import PartyMonthwiseSale from "./SparepartsPages/Reports/PartyMonthwiseSale";
import PartyWiseItemList from "./SparepartsPages/Reports/PartyWiseItemList";
import PartyLedgerReport from "./SparepartsPages/Reports/PartyLedgerReport";
import DebitNoteRegister from "./SparepartsPages/Reports/DebitNoteRegister";
import MoneyReceiptRegister from "./SparepartsPages/Reports/MoneyReceiptRegister";
import TransitRegister from "./SparepartsPages/Reports/TransitRegister";
import PurchaseReturn from "./SparepartsPages/PurchaseReturn/PurchaseReturn";
import ProvisionalMoneyReceipt from "./SparepartsPages/MoneyReceipt/ProvisionalMoneyReceipt";
import StockAdjust from "./SparepartsPages/StockAdjust/StockAdjust";
import Debitnote from "./SparepartsPages/DebitNote/Debitnote";
import AccountMaster from "./SparepartsPages/AccountMaster/AccountMaster";
import SaleRegisterAll from "./SparepartsPages/Reports/SaleRegisterAll";
import CreateUser from "./SparepartsPages/AdminPages/CreateUser";
import CreatedUsers from "./SparepartsPages/AdminPages/CreatedUsers";
import BillTransfer from "./SparepartsPages/BillTransfer/BillTransfer";
import PurchaseReports from "./SparepartsPages/AdminPages/PurchaseReports";
import UnlockProvisionalMR from "./SparepartsPages/AdminPages/UnlockProvisionalMR";
import AdminDashHome from "./SparepartsPages/AdminPages/AdminDashHome";
import StockTransfer from "./SparepartsPages/StockTransfer/StockTransfer";
import ReceiveStockTransfer from "./SparepartsPages/ReceiveStockTransfer/ReceiveStockTransfer";
import MrpUpdate from "./SparepartsPages/MrpUpdate/MrpUpdate";
import AltItemExcelUpload from "./SparepartsPages/AltItemExcelUpload/AltItemExcelUpload";
import AdminInstructions from "./SparepartsPages/AdminPages/AdminInstructions";
import ShortExcess from "./SparepartsPages/ShortExcss/ShortExcess";
import Invoice from "./SparepartsPages/eInvoiceModule/Invoice";
import CrediteNoteInvoice from "./SparepartsPages/SaleReturn/CrediteNoteInvoice";
import DebitNote from "./SparepartsPages/Reports/DebitNote";
import SaleBillWordPrint from "./SparepartsPages/AdminPages/SaleBillWordPrint";
import WayBill from "./SparepartsPages/SaleBill/WayBill";
import Deliverynoteview from "./SparepartsPages/SaleBill/Deliverynoteview";
import DeliveryNoteReg from "./SparepartsPages/Reports/DeliveryNoteReg";
import Estimate from "./SparepartsPages/Estimate/Estimate";
import SubledgerOp from "./SparepartsPages/SubledgerOpening/SubledgerOp";
import ReasonMaster from "./SparepartsPages/CreditNoteReasonMaster/ReasonMaster";
import { useEffect, useState, useRef } from "react";
import SaleAuditReport from "./SparepartsPages/Reports/SaleAuditReport";
import TvsMIS from "./SparepartsPages/Reports/TvsMIS";
import StkTransferWayBill from "./SparepartsPages/StockTransfer/StkTransferWayBill";
import StkTransferRegister from "./SparepartsPages/Reports/StkTransferRegister";
import TyreTubeSale from "./SparepartsPages/Reports/TyreTubeSale";
import ElegantSaleReport from "./SparepartsPages/Reports/ElegantSaleReport";
import { io } from "socket.io-client";
import { Button, TextField } from "@mui/material";
import ShortExcessReg from "./SparepartsPages/Reports/ShortExcessReg";
import ItemOpCorrection from "./SparepartsPages/AdminPages/ItemOpCorrection";
import WarrantyEntry from "./SparepartsPages/WarrantyModule/WarrantyEntry";
import WarrantyReturn from "./SparepartsPages/WarrantyModule/WarrantyReturn";
import ShippingFlagFix from "./SparepartsPages/AdminPages/ShippingFlagFix";
import EmodeBoxno from "./SparepartsPages/SaleBill/EmodeBoxno";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function App() {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        // Mobile or tablet size (adjust as per your need)
        setIsMobileOrTablet(true);
      } else {
        setIsMobileOrTablet(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [socket, setSocket] = useState(null);
  const [notif, setNotif] = useState("");
  const [viewDevmenu, setViewDevMenu] = useState(false);
  const [devMessage, setDevMessage] = useState("");
  //dialoge
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleDevSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = { devMessage };
      socket.emit("sendNotif", devMessage);
      const updateTemp = await fetch("/dev/update/messages/1", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      setOpen(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleDevMenuOpen = async (e) => {
    console.log(e);
    try {
      if (e.ctrlKey && e.shiftKey && e.key === "!") {
        console.log("Ctrl + Shift + ! was pressed");
        setViewDevMenu(true);
        setOpen(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const fetchPrevNotif = async (e) => {
    try {
      const data = await fetch("/dev/messages");
      const message = await data.json();
      setNotif(message.messages);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    const handleKeyDown = (e) => handleDevMenuOpen(e);

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      //setViewDevMenu(false)
    };
  }, []);

  const handleDevMenuClose = async (e) => {
    // console.log(e);
    try {
      if (e.ctrlKey && e.shiftKey && e.key === "@") {
        // console.log("Ctrl + Shift + @ was pressed");
        // setViewDevMenu(false);
        setOpen(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => handleDevMenuClose(e);

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      setViewDevMenu(false);
    };
  }, []);
  useEffect(() => {
    const newSocket = io("http://68.178.167.193:4000");
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket === null) return;
    socket.on("getNotif", (res) => {
      setNotif(res);
    });
    fetchPrevNotif();
    return () => {
      socket.off("getNotif");
    };
  }, [socket, notif]);

  const messageStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f2f2f2",
    color: "#333",
    textAlign: "center",
    fontSize: "24px",
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };
  return (
    <>
      {notif !== "" && (
        <marquee
          scrollamount="10"
          style={{
            color: "red",
            fontSize: 18,
            backgroundColor: "yellow",
            padding: 3,
          }}
        >
          {`📢${notif}📢`}
        </marquee>
      )}

      {/* {viewDevmenu && (
        <>
          <TextField
            size="medium"
            fullWidth
            onChange={(e) => setDevMessage(e.target.value)}
          ></TextField>
          <Button size="medium" onClick={handleDevSubmit}>
            YAY!
          </Button>
        </>
      )} */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          READY!!! STEADY!!!
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <TextField
            variant="standard"
            sx={{
              width: 400,
            }}
            size="small"
            onChange={(e) => setDevMessage(e.target.value)}
          ></TextField>
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            color="success"
            size="medium"
            onClick={handleDevSubmit}
          >
            GOO!
          </Button>
        </DialogContent>
      </BootstrapDialog>
      <Switch>
        {isMobileOrTablet ? (
          <div style={messageStyle}>
            <h1>This website is not compatible with mobile and tablet</h1>
          </div>
        ) : (
          <>
            {/* ADMIN ROUTES */}
            <Route
              exact
              path="/"
              render={(props) => <DashboardOptions {...props} />}
            />
            {/* <Route
          exact
          path="/admin"
          render={(props) => <LandingPage {...props} />}
        /> */}

            {/* Spareparts User Routes */}
            <Route
              exact
              path="/sparepartsLogin"
              render={(props) => <Loginpage {...props} />}
            />
            <Route exact path="/welcomepage" render={() => <WelcomePage />} />
            <Route
              exact
              path="/spareparts"
              render={(props) => <SpareLandingItemMaster {...props} />}
            />
            <Route
              exact
              path="/other"
              render={(props) => <Other {...props} />}
            />
            <Route
              exact
              path="/ledger"
              render={(props) => <LedgerMaster {...props} />}
            />
            <Route
              exact
              path="/mainledger"
              render={(props) => <MainLedger {...props} />}
            />
            <Route
              exact
              path="/salesman"
              render={(props) => <Salesman {...props} />}
            />
            <Route
              exact
              path="/transporter"
              render={(props) => <Transporter {...props} />}
            />
            <Route
              exact
              path="/makerpicker"
              render={(props) => <MakerPicker {...props} />}
            />
            <Route
              exact
              path="/subledger"
              render={(props) => <Subledger {...props} />}
            />
            <Route
              exact
              path="/branchsubledger"
              render={(props) => <BranchSubledger {...props} />}
            />
            <Route
              exact
              path="/customerOrder"
              render={(props) => <CustomerOrder {...props} />}
            />
            <Route
              exact
              path="/purchaseorder"
              render={(props) => <PurchaseOrder {...props} />}
            />
            {/* <Route
          exact
          path="/purchaseorder"
          render={(props) => <PurOrder {...props} />}
        /> */}
            <Route
              exact
              path="/transit"
              render={(props) => <Transit {...props} />}
            />
            <Route
              exact
              path="/backorder"
              render={(props) => <BackOrder {...props} />}
            />
            <Route
              exact
              path="/alternateitem"
              render={(props) => <AlternetItem {...props} />}
            />
            <Route
              exact
              path="/model"
              render={(props) => <Model {...props} />}
            />
            <Route
              exact
              path="/technician"
              render={(props) => <Technicians {...props} />}
            />
            <Route
              exact
              path="/jobtype"
              render={(props) => <JobType {...props} />}
            />
            <Route
              exact
              path="/jobcard"
              render={(props) => <Jobcard {...props} />}
            />
            <Route
              exact
              path="/salebill"
              render={(props) => <SaleBill {...props} />}
            />
            <Route
              exact
              path="/banks"
              render={(props) => <Banks {...props} />}
            />
            <Route
              exact
              path="/narration"
              render={(props) => <Narrations {...props} />}
            />
            <Route
              exact
              path="/moneyreceipt"
              render={(props) => <MoneyReceipt {...props} />}
            />
            <Route
              exact
              path="/provisionalmr"
              render={(props) => <ProvisionalMoneyReceipt {...props} />}
            />
            <Route
              exact
              path="/directsale"
              render={(props) => <DirectSale {...props} />}
            />
            <Route
              exact
              path="/testreport"
              render={(props) => <TestReport2 {...props} />}
            />
            <Route
              exact
              path="/salereturn"
              render={(props) => <SaleReturn {...props} />}
            />
            <Route
              exact
              path="/combinedsalebill"
              render={(props) => <CombinedSalebill {...props} />}
            />
            <Route
              exact
              path="/saleregistersummary"
              render={(props) => <SaleRegdSum {...props} />}
            />
            <Route
              exact
              path="/salesmanwisebilldetail"
              render={(props) => <SalesmanwiseBilldetail {...props} />}
            />
            <Route
              exact
              path="/saleorderstatus"
              render={(props) => <SaleOrderStatus {...props} />}
            />
            <Route
              exact
              path="/topslow"
              render={(props) => <TopSlow {...props} />}
            />
            <Route
              exact
              path="/stkadjustregister"
              render={(props) => <StockAdjustRegister {...props} />}
            />
            <Route
              exact
              path="/stksummary"
              render={(props) => <StockSummary {...props} />}
            />
            <Route
              exact
              path="/stkldgrbincard"
              render={(props) => <StockLedgerBinCard {...props} />}
            />
            <Route
              exact
              path="/nmovingpartlist"
              render={(props) => <NonMovingPartsList {...props} />}
            />
            <Route
              exact
              path="/billoutstanding"
              render={(props) => <BillOutstanding {...props} />}
            />
            <Route
              exact
              path="/rackwisestklist"
              render={(props) => <RackwiseStockList {...props} />}
            />
            <Route
              exact
              path="/pickerperformance"
              render={(props) => <PickerPerformance {...props} />}
            />
            <Route
              exact
              path="/purregister"
              render={(props) => <PurchaseRegister {...props} />}
            />
            <Route
              exact
              path="/corderreport"
              render={(props) => <CustomerOrderReport {...props} />}
            />
            <Route
              exact
              path="/salertrnregd"
              render={(props) => <SaleReturnRegister {...props} />}
            />
            <Route
              exact
              path="/warrantyregister"
              render={(props) => <WarrantyRegister {...props} />}
            />
            <Route
              exact
              path="/oilconsumption"
              render={(props) => <OilConsumption {...props} />}
            />
            <Route
              exact
              path="/salesmanpartysum"
              render={(props) => <SalesmanPartySummary {...props} />}
            />
            <Route
              exact
              path="/govtgst"
              render={(props) => <GovtGst {...props} />}
            />
            <Route
              exact
              path="/hsnreport"
              render={(props) => <HsnReport {...props} />}
            />
            <Route
              exact
              path="/bajajmis"
              render={(props) => <BajajMIS {...props} />}
            />
            <Route
              exact
              path="/partywiseorder"
              render={(props) => <PartywiseOrderBill {...props} />}
            />
            <Route
              exact
              path="/partymonthwisesale"
              render={(props) => <PartyMonthwiseSale {...props} />}
            />
            <Route
              exact
              path="/partywiseitemlist"
              render={(props) => <PartyWiseItemList {...props} />}
            />
            <Route
              exact
              path="/partyledger"
              render={(props) => <PartyLedgerReport {...props} />}
            />
            <Route
              exact
              path="/debitnoteregd"
              render={(props) => <DebitNoteRegister {...props} />}
            />
            <Route
              exact
              path="/moneyreceiptregister"
              render={(props) => <MoneyReceiptRegister {...props} />}
            />
            <Route
              exact
              path="/transitregister"
              render={(props) => <TransitRegister {...props} />}
            />
            <Route
              exact
              path="/purchasereturn"
              render={(props) => <PurchaseReturn {...props} />}
            />
            <Route
              exact
              path="/stkadjust"
              render={(props) => <StockAdjust {...props} />}
            />
            <Route
              exact
              path="/debitnote"
              render={(props) => <Debitnote {...props} />}
            />
            <Route
              exact
              path="/acmaster"
              render={(props) => <AccountMaster {...props} />}
            />
            <Route
              exact
              path="/saleregisterall"
              render={(props) => <SaleRegisterAll {...props} />}
            />
            <Route
              exact
              path="/billtransfer"
              render={(props) => <BillTransfer {...props} />}
            />
            <Route
              exact
              path="/usercreate"
              render={(props) => <CreateUser {...props} />}
            />
            <Route
              exact
              path="/createduser"
              render={(props) => <CreatedUsers {...props} />}
            />
            <Route
              exact
              path="/purapprove"
              render={(props) => <PurchaseReports {...props} />}
            />
            <Route
              exact
              path="/unprovmr"
              render={(props) => <UnlockProvisionalMR {...props} />}
            />
            <Route
              exact
              path="/admin"
              render={(props) => <AdminDashHome {...props} />}
            />
            <Route
              exact
              path="/stktrans"
              render={(props) => <StockTransfer {...props} />}
            />
            <Route
              exact
              path="/rcvstktransfer"
              render={(props) => <ReceiveStockTransfer {...props} />}
            />
            <Route
              exact
              path="/mrpupdate"
              render={(props) => <MrpUpdate {...props} />}
            />
            <Route
              exact
              path="/altitem"
              render={(props) => <AltItemExcelUpload {...props} />}
            />
            <Route
              exact
              path="/admininst"
              render={(props) => <AdminInstructions {...props} />}
            />
            <Route
              exact
              path="/shortexcess"
              render={(props) => <ShortExcess {...props} />}
            />
            <Route
              exact
              path="/einvoice"
              render={(props) => <Invoice {...props} />}
            />
            <Route
              exact
              path="/cninvoice"
              render={(props) => <CrediteNoteInvoice {...props} />}
            />
            <Route
              exact
              path="/debitnoteprint"
              render={(props) => <DebitNote {...props} />}
            />
            <Route
              exact
              path="/salebilldoc"
              render={(props) => <SaleBillWordPrint {...props} />}
            />
            <Route
              exact
              path="/ewaybill"
              render={(props) => <WayBill {...props} />}
            />
            <Route
              exact
              path="/deliverynoteview"
              render={(props) => <Deliverynoteview {...props} />}
            />
            <Route
              exact
              path="/dnotereg"
              render={(props) => <DeliveryNoteReg {...props} />}
            />
            <Route
              exact
              path="/estimate"
              render={(props) => <Estimate {...props} />}
            />
            <Route
              exact
              path="/sublgrop"
              render={(props) => <SubledgerOp {...props} />}
            />
            <Route
              exact
              path="/reasonmaster"
              render={(props) => <ReasonMaster {...props} />}
            />
            <Route
              exact
              path="/saleaudit"
              render={(props) => <SaleAuditReport {...props} />}
            />
            <Route
              exact
              path="/tvsmis"
              render={(props) => <TvsMIS {...props} />}
            />
            <Route
              exact
              path="/stkewaybill"
              render={(props) => <StkTransferWayBill {...props} />}
            />
            <Route
              exact
              path="/stktrnsreg"
              render={(props) => <StkTransferRegister {...props} />}
            />
            <Route
              exact
              path="/tyretube"
              render={(props) => <TyreTubeSale {...props} />}
            />
            <Route
              exact
              path="/elegant"
              render={(props) => <ElegantSaleReport {...props} />}
            />
            <Route
              exact
              path="/stexcreg"
              render={(props) => <ShortExcessReg {...props} />}
            />
            <Route
              exact
              path="/itemopcor"
              render={(props) => <ItemOpCorrection {...props} />}
            />
            <Route
              exact
              path="/warranty"
              render={(props) => <WarrantyEntry {...props} />}
            />
            <Route
              exact
              path="/warrantyrtn"
              render={(props) => <WarrantyReturn {...props} />}
            />
            <Route
              exact
              path="/shipflagfix"
              render={(props) => <ShippingFlagFix {...props} />}
            />
            <Route
              exact
              path="/emodebox"
              render={(props) => <EmodeBoxno {...props} />}
            />
          </>
        )}
      </Switch>
    </>
  );
}

export default App;
