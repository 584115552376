import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import AdminSidebar from "../../Components/AdminPanel/AdminSidebar";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Box, Stack, Autocomplete, Button, Checkbox } from "@mui/material";
import TextField from "@mui/material/TextField";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Task from "@mui/icons-material/Task";
import { toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";
toast.configure();
const ShippingFlagFix = () => {
  const userToken = LocalstorageHelper.GetAdminToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/" />;
    }
  };
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  const [billnoopts, setbillnoopts] = useState([]);
  const [billno, setbillno] = useState(null);
  const [finyearname, setfinyearname] = useState();
  const [finopts, setfinopts] = useState([]);
  const billtypeopts = [
    {
      value: 1,
      label: "Invoice",
    },
    {
      value: 2,
      label: "Spare",
    },
    {
      value: 3,
      label: "Labour",
    },
  ];
  const [billType, setBillType] = useState(1);
  const [shipingaddflag, setshipingaddflag] = useState(true);
  //console.log("shipingaddflag", shipingaddflag);
  const [shpname, setshpname] = useState("");
  const [shpgstno, setshpgstno] = useState("");
  const [shpaddrs1, setshpaddrs1] = useState("");
  const [shpaddrs2, setshpaddrs2] = useState("");
  const [shppincode, setshppincode] = useState(null);
  const [shplocation, setshplocation] = useState(null);
  const [shplocationopts, setshplocationopts] = useState([]);
  const [editdisable, seteditdisable] = useState(false);
  //finyear
  async function getfindetails() {
    try {
      const response = await fetch("/admin/findetails", {
        method: "GET",
        headers: { token: userToken },
      });
      const jsonData = await response.json();
      setfinopts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getfindetails();
  }, []);
  //areas
  async function getAreaOpts() {
    try {
      const response = await fetch("/admin/shippingfix/areas", {
        method: "GET",
        headers: { token: userToken },
      });
      const jsonData = await response.json();
      setshplocationopts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getAreaOpts();
  }, []);
  //billnos
  async function getbillnos() {
    try {
      const response = await fetch(
        `/admin/shippingfix/billnos/${finyearname}/${billType}`,
        {
          method: "GET",
          headers: { token: userToken },
        }
      );
      const jsonData = await response.json();
      setbillnoopts(jsonData);
      //   console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    if (finyearname !== undefined) {
      getbillnos();
    }
  }, [billType, finyearname]);

  //shipping details
  async function getbilldata() {
    try {
      const body = { billheaderid: billno.billheaderid };
      const response = await fetch("/admin/shippingfix/billdetails", {
        method: "POST",
        headers: { token: userToken, "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      const jsonData = await response.json();
      console.log(jsonData);
      if (jsonData.sameshippingflag === true) {
        setshipingaddflag(true);
        setshpaddrs1("");
        setshpaddrs2("");
        setshppincode("");
        setshpgstno("");
        setshpname("");
        setshplocation(null);
      } else {
        setshipingaddflag(false);
        setshpaddrs1(jsonData.shpaddrs1);
        setshpaddrs2(jsonData.shpaddrs2);
        setshppincode(jsonData.shppincode);
        setshpgstno(jsonData.shpgstno);
        setshpname(jsonData.shpname);
        setshplocation({
          destid: jsonData.destid,
          destname: jsonData.destname,
        });
      }
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    if (billno !== null) {
      getbilldata();
    }
  }, [billno]);

  //update
  const handleUpdate = async (e) => {
    seteditdisable(true);
    try {
      const body = {
        billheaderid: billno.billheaderid,
        shipingaddflag,
        shpname,
        shpgstno,
        shpaddrs1,
        shpaddrs2,
        shppincode,
        shpdestid: shplocation?.destid,
      };
      const response = await fetch("/admin/shippingfix/up", {
        method: "POST",
        headers: { "Content-Type": "application/json", token: userToken },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();
      if (parseRes.type === "success") {
        toast.success(parseRes.message);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        toast.error(parseRes.message);
        seteditdisable(false);
      }
    } catch (err) {
      console.log(err.message);
      seteditdisable(false);
    }
  };
  return (
    <>
      <AdminSidebar />
      <Stack spacing={2} columnGap={2} direction="row" mt={2} ml={"10%"} mb={2}>
        <TextField
          id="Finyear"
          required
          name="Finyear"
          select
          label="Select Finyear"
          value={finyearname}
          onChange={(e) => setfinyearname(e.target.value)}
          variant="outlined"
          sx={{
            width: "10%",
          }}
          size="small"
        >
          {finopts.map((option) => (
            <MenuItem key={option.finyearid} value={option.finyearid}>
              {option.finname}
            </MenuItem>
          ))}
        </TextField>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Bill Type</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={1}
            name="radio-buttons-group"
            row
          >
            {billtypeopts.map((opt) => (
              <FormControlLabel
                key={opt.value}
                value={opt.value}
                control={<Radio />}
                label={opt.label}
                onChange={(e) => setBillType(e.target.value)}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Autocomplete
          id="bill"
          value={billno}
          filterOptions={filterOptions}
          onChange={(event, newValue) => {
            setbillno(newValue);
          }}
          options={billnoopts}
          getOptionLabel={(option) => option.billno}
          sx={{
            width: "30% ",
          }}
          renderInput={(billopts) => (
            <TextField required {...billopts} placeholder="Bill No" />
          )}
          size="small"
        />
      </Stack>
      <Stack spacing={2} columnGap={2} direction="row" mt={2} ml={"10%"} mb={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={shipingaddflag}
              onChange={(e) => setshipingaddflag(e.target.checked)}
            />
          }
          label="Shipping Address Same As Address"
        />
      </Stack>
      <Stack spacing={2} columnGap={2} direction="row" mt={2} ml={"10%"} mb={2}>
        <TextField
          id="shpname"
          label="ShippingName"
          value={shpname}
          onChange={(e) => setshpname(e.target.value)}
          sx={{
            width: 300,
          }}
          size="small"
        />
        <TextField
          id="ShpGstno"
          label="ShippingGstno"
          value={shpgstno}
          onChange={(e) => setshpgstno(e.target.value)}
          sx={{
            width: 400,
          }}
          size="small"
        />
        <Autocomplete
          id="ShpLoc"
          value={shplocation}
          filterOptions={filterOptions}
          onChange={(event, newValue) => {
            setshplocation(newValue);
          }}
          options={shplocationopts}
          getOptionLabel={(option) => option.destname}
          sx={{
            width: 500,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="ShippingLocation"
              variant="outlined"
              size="small"
            />
          )}
        />
      </Stack>
      <Stack spacing={2} columnGap={2} direction="row" mt={2} ml={"10%"} mb={2}>
        <TextField
          id="ShippingAddrs1"
          label="ShippingAddrs1"
          value={shpaddrs1}
          onChange={(e) => setshpaddrs1(e.target.value)}
          sx={{
            width: 500,
          }}
          size="small"
        />
        <TextField
          id="ShippingAddrs2"
          label="ShippingAddrs2"
          value={shpaddrs2}
          onChange={(e) => setshpaddrs2(e.target.value)}
          sx={{
            width: 500,
          }}
          size="small"
        />
        <TextField
          id="ShippingPincode"
          label="ShippingPincode"
          value={shppincode}
          InputLabelProps={{ shrink: true || shppincode }}
          onChange={(e) => setshppincode(e.target.value)}
          sx={{
            width: 200,
          }}
          size="small"
        />
      </Stack>
      <Stack
        spacing={2}
        columnGap={2}
        direction="row"
        mt={2}
        justifyContent={"center"}
        mb={2}
      >
        <Button disabled={editdisable} onClick={handleUpdate} size="large" color="secondary" variant="contained">
          Update
        </Button>
      </Stack>
      {redirectToLoginPage()}
    </>
  );
};

export default ShippingFlagFix;
