import React, { useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  Modal,
  Typography,
  Chip,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Switch,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";
import BillSearch from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { createFilterOptions } from "@mui/material/Autocomplete";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect, Link } from "react-router-dom";
import { purple } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { green } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import SyncIcon from "@mui/icons-material/Sync";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PrintBill from "./PrintBill";
import EInvoice from "./EInvoice";
import Deliverynote from "./Deliverynote";
import { darken, lighten } from "@mui/material/styles";
toast.configure();
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  boxShadow: "2px 2px 6px  #404142",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));
const EmodeBoxno = () => {
  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };
  //SET SEARCHFIELD'S CONFIGURATION
  const OPTIONS_LIMIT = 15;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [partyinput, setpartyinput] = useState(null);
  const [partyopts, setpartyopts] = useState([]);
  const [orderinput, setorderinput] = useState(null);
  const [orderopts, setorderopts] = useState([]);
  const [rows, setrows] = useState([]);
  const handleDeleteClick = (id) => () => {
    setrows(rows.filter((row) => row.itemid !== id));
  };
  const columns = [
    // {
    //   field: "actions",
    //   type: "actions",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Del",
    //   width: 20,
    //   cellClassName: "actions",
    //   getActions: ({ id }) => {
    //     return [
    //       <GridActionsCellItem
    //         icon={<DeleteIcon />}
    //         label="Delete"
    //         onClick={handleDeleteClick(id)}
    //         color="inherit"
    //       />,
    //     ];
    //   },
    // },
    {
      field: "serial",
      headerClassName: "super-app-theme--header",
      headerName: "Srl",
      type: "number",
      textAlign: "left",
      width: 30,
    },
    {
      field: "partno",
      headerClassName: "super-app-theme--header",
      headerName: "Partno",
      cellClassName: "custom-font-size",
      width: 350,
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{params.value}</div>
      ),
    },
    {
      field: "stkqty",
      headerClassName: "super-app-theme--header",
      headerName: "StkQty",
      width: 80,
      type: "number",
    },
    {
      field: "rackname",
      headerClassName: "super-app-theme--header",
      headerName: "Rack",
      width: 70,
      type: "number",
    },
    {
      field: "ordqty",
      headerClassName: "super-app-theme--header",
      headerName: "OrdQty",
      width: 80,
      type: "number",
      //   editable: true,
      //   valueGetter: (params) => {
      //     return params.row.ordqty;
      //   },
      //   valueSetter: (params) => {
      //     const ordqty = params.value;
      //     const saleqty =
      //       parseFloat(params.row.stkqty) > parseFloat(params.value)
      //         ? params.value
      //         : params.row.stkqty;
      //     const losqty = parseFloat(params.value - saleqty);
      //     const value = parseFloat(parseFloat(ordqty) * params.row.price).toFixed(2);
      //     const disamt = parseFloat((value * params.row.dispers) / 100).toFixed(
      //       2
      //     );
      //     const taxable = value - disamt;
      //     const gstamt = parseFloat((taxable * params.row.gstpers) / 100).toFixed(
      //       2
      //     );
      //     const netamt = parseFloat(
      //       parseFloat(taxable) + parseFloat(gstamt)
      //     ).toFixed(2);
      //     if (parseInt(mode) === 1) {
      //       const upd_obj = rows.findIndex(
      //         (obj) => obj.itemid === params.row.itemid
      //       );
      //       rows[upd_obj].ordqty = ordqty;
      //       rows[upd_obj].saleqty = saleqty;
      //       rows[upd_obj].losqty = losqty;
      //       rows[upd_obj].value = value;
      //       rows[upd_obj].disamt = disamt;
      //       rows[upd_obj].taxable = taxable;
      //       rows[upd_obj].gstamt = gstamt;
      //       rows[upd_obj].netamt = netamt;
      //       return {
      //         ...params.row,
      //         ordqty,
      //         saleqty,
      //         value,
      //         losqty,
      //         disamt,
      //         taxable,
      //         gstamt,
      //         netamt,
      //       };
      //     } else {
      //       const upd_obj = emodearray.findIndex(
      //         (obj) => obj.itemid === params.row.itemid
      //       );
      //       emodearray[upd_obj].ordqty = ordqty;
      //       emodearray[upd_obj].saleqty = saleqty;
      //       emodearray[upd_obj].losqty = losqty;
      //       emodearray[upd_obj].value = value;
      //       emodearray[upd_obj].disamt = disamt;
      //       emodearray[upd_obj].taxable = taxable;
      //       emodearray[upd_obj].gstamt = gstamt;
      //       emodearray[upd_obj].netamt = netamt;
      //       return {
      //         ...params.row,
      //         ordqty,
      //         saleqty,
      //         value,
      //         disamt,
      //         losqty,
      //         taxable,
      //         gstamt,
      //         netamt,
      //       };
      //     }
      //   },
    },
    {
      field: "boxno",
      headerClassName: "super-app-theme--header",
      headerName: "BoxNo",
      width: 80,
      type: "number",
      editable: true,
      valueSetter: (params) => {
        const boxno = params.value;
        const upd_obj = rows.findIndex(
          (obj) => obj.itemid === params.row.itemid
        );
        rows[upd_obj].boxno = boxno;
        return { ...params.row, boxno };
      },
    },
    {
      field: "scannedqty",
      headerClassName: "super-app-theme--header",
      headerName: "ScanQty",
      width: 80,
      type: "number",
    },
    {
      field: "losqty",
      headerClassName: "super-app-theme--header",
      headerName: "LosQty",
      width: 80,
      type: "number",
    },
    {
      field: "saleqty",
      headerClassName: "super-app-theme--header",
      headerName: "SaleQty",
      type: "number",
      width: 80,
      editable: false,
    },
    {
      field: "mrp",
      headerClassName: "super-app-theme--header",
      headerName: "MRP",
      width: 80,
      type: "number",
    },
    {
      field: "price",
      headerClassName: "super-app-theme--header",
      headerName: "Price",
      width: 80,
      type: "number",
    },
    {
      field: "value",
      headerClassName: "super-app-theme--header",
      headerName: "Value",
      width: 100,
      type: "number",
    },
    {
      field: "dispers",
      headerClassName: "super-app-theme--header",
      headerName: "Dis%",
      width: 80,
      type: "number",
    },
    {
      field: "disamt",
      headerClassName: "super-app-theme--header",
      headerName: "DisAmt",
      width: 100,
      type: "number",
    },
    {
      field: "taxable",
      headerClassName: "super-app-theme--header",
      headerName: "Taxable",
      width: 80,
      type: "number",
    },
    {
      field: "gstpers",
      headerClassName: "super-app-theme--header",
      headerName: "GST%",
      width: 60,
      type: "number",
    },
    {
      field: "gstamt",
      headerClassName: "super-app-theme--header",
      headerName: "GST Amt",
      width: 120,
      type: "number",
    },
    {
      field: "netamt",
      headerClassName: "super-app-theme--header",
      headerName: "Net Amt",
      width: 120,
      type: "number",
    },
  ];
  //party
  async function getparty() {
    try {
      const response = await fetch("/corder/scanned/party", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setpartyopts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getparty();
  }, []);

  //orders
  async function getorders() {
    try {
      const response = await fetch(
        `/corder/scanned/orders/${partyinput.subledgerid}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const jsonData = await response.json();
      setorderopts(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    if (partyinput) {
      getorders();
    }
  }, [partyinput]);

  //orderdata
  async function getorderdata() {
    try {
      const response = await fetch(
        `/corder/scanned/orderdata/${orderinput.corderheaderid}`,
        {
          method: "GET",
          headers: { sptoken: userToken },
        }
      );
      const jsonData = await response.json();
      setrows(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    if (orderinput) {
      getorderdata();
    }
  }, [orderinput]);

  //update
  const handleEditmodeSubmit = async (e) => {
    e.preventDefault();
    try {
      setDisableUpdate(true);
      const body = {
        rows,
        headerid: orderinput.corderheaderid,
      };
      const response = await fetch("/corder/scanned/up", {
        method: "POST",
        headers: { "Content-Type": "application/json", sptoken: userToken },
        body: JSON.stringify(body),
      });
      const parseRes = await response.json();
      if (parseRes.type === "success") {
        toast.success(parseRes.message);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        toast.error(parseRes.message);
        setDisableUpdate(false);
      }
    } catch (err) {
      console.log(err.message);
      setDisableUpdate(false);
    }
  };
  return (
    <div>
      <Sidebar
        Title={"EDIT MODE FOR BOXNO."}
        expandedPanel={"panel2"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            autoComplete="off"
          >
            <Stack
              spacing={1}
              mb={"10px"}
              direction="row"
              justifyContent={"center"}
            >
              <Autocomplete
                //disabled={searchdisabled}
                id="partyno"
                value={partyinput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setpartyinput(newValue);
                }}
                options={partyopts}
                getOptionLabel={(option) => option.partydetails}
                sx={{
                  width: 420,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Search Party"
                  />
                )}
              />
              <Autocomplete
                //disabled={searchdisabled}
                id="orderno"
                value={orderinput}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  setorderinput(newValue);
                }}
                options={orderopts}
                getOptionLabel={(option) => option.orderno}
                sx={{
                  width: 420,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Search Order"
                  />
                )}
              />
            </Stack>
            <Stack
              spacing={1}
              mb={"10px"}
              direction="row"
              justifyContent={"center"}
            >
              <Box
                sx={{
                  height: 550,
                  width: "100%",
                  position: "relative",
                  mt: "20px",
                  boxShadow: "2px 6px 15px #5a5a5a",
                  // border: "0.5px solid black",
                  // borderRadius: "5px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#3bb9ff",
                    color: "#000",
                  },
                  "& .custom-font-size": {
                    fontSize: 13, // Adjust the font size as needed
                  },
                }}
              >
                <DataGrid
                  sx={{
                    border: "1px solid #000",
                    "& .MuiDataGrid-cell:focus": {
                      outline: "2px solid red", // Change this to the desired color
                      backgroundColor: "gray",
                      color: "white",
                    },
                  }}
                  rowHeight={35}
                  headerHeight={30}
                  showColumnRightBorder={true}
                  rows={rows}
                  columns={columns}
                  getRowId={(row) => row.serial}
                />
              </Box>
            </Stack>
            <Stack
              spacing={1}
              mb={"10px"}
              direction="row"
              justifyContent={"center"}
            >
              <Button
                type="submit"
                onClick={handleEditmodeSubmit}
                disabled={disableUpdate}
                variant="contained"
                color="primary"
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
              >
                UPDATE ORDER
              </Button>
            </Stack>
          </Box>
        }
      />
      {redirectToLoginPage()}
    </div>
  );
};

export default EmodeBoxno;
